import { subdomain } from "./env.js";
import './App.css';

const redirectUrl = `https://${subdomain}uship-logistics-admin.pages.dev/api/auth-callback`;

function App() {
  const loginUrl = `https://login.ushipdev.com/oauth2/default/v1/authorize?client_id=0oa1py701c673dXwb0h8&response_type=code&scope=openid+offline_access&redirect_uri=${encodeURIComponent(redirectUrl)}&state=${encodeURIComponent(Date.now())}`;
  return (
    <div className="App">
      <a href={loginUrl}>Login</a>
    </div>
  );
}

export default App;
